import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";

import defaultCardImage from "../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

import ChartIconImage from "../../images/chart.svg";
import HomeIconImage from "../../images/home.svg";
import DashboardIconImage from "../../images/dashboard.svg";
import SmartphoneIconImage from "../../images/smartphone.svg";
import APIIconImage from "../../images/api.svg";
import TaskIconImage from "../../images/task.svg";
import SimpleIconImage from "../../images/simple-icon.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-orange-500 rounded-lg mt-12`}
  .imageContainer {
    ${tw`border-2 border-orange-500 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-orange-500`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default () => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const cards = [
    { imageSrc: DashboardIconImage, title: "One Management Console", description: "As a developer you can manage your schemes, properties and snags all in one web interface." },
    { imageSrc: TaskIconImage, title: "Task your Contractors" , description: "Invite your contractors to Snagglist and pass issues directly to them for resolution." },  
    { imageSrc: ChartIconImage, title: "Insightful Reporting", description: "Find your problem contractors and carry our trend analysis with our out of the box reporting dashboard."},
    { imageSrc: SmartphoneIconImage, title: "Mobile Apps", description: "Your customers can use our native iOS and Android Apps to raise, manage and be notified with updates on issues with ease. " },
    { imageSrc: HomeIconImage, title: "Bulk Property Import", description: "Import your properties via CSV using our built in property import service." },
    { imageSrc: APIIconImage, title: "API Integration" , description: "Our comprehensive API allows you to integrate Snagglist with your existing systems." },
    // { imageSrc: SimpleIconImage, title: "A picture paints a thousand  words" , description: "Customers can attach photos directly to issues via the mobile app. Need more photos? The customer can add more at any time."}
  ];

  return (
    <Container id="features">
      <ThreeColumnContainer>
        <Heading>Snagglist <span tw="text-orange-500">Features</span></Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage}  alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
