import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React from "react";
import HomePage from "pages/HomePage"
import PrivacyPolicyPage from "pages/PrivacyPolicy"
import TermsOfServicePage from "pages/TermsOfService"
import PlanSelect from "pages/PlanSelect";
import PlanSubmit from "pages/PlanSubmit";
import DemoCreateAccount from "pages/DemoCreateAccount";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import DemoAccountSuccess from "pages/DemoAccountSuccess";

export default function App() {

  return (
    <Router>
      <Switch>
        <Route path="/" exact>
          <HomePage />
        </Route>
        <Route path="/privacypolicy" exact>
          <PrivacyPolicyPage />
        </Route>
        <Route path="/terms" exact>
          <TermsOfServicePage />
        </Route>
        <Route path="/planselect/:plan/:cost" exact>
          <PlanSelect />
        </Route>
        <Route path="/plansubmit" exact>
          <PlanSubmit />
        </Route>
        <Route path="/createdemoaccount" exact>
          <DemoCreateAccount />
        </Route>
        <Route path="/demoaccountsuccess" exact>
          <DemoAccountSuccess />
        </Route>
      </Switch>
    </Router>
  );
}