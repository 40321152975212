import React from "react";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import illustration from "images/WebAppScreenshot.png";
import { ReactComponent as LoginIcon } from "feather-icons/dist/icons/log-in.svg";
import Header from "../components/headers/light.js";
import Footer from "../components/footers/Footer.js";
import { Redirect } from "react-router-dom";

const Container = tw(ContainerBase)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const MainContent = tw.div`mt-12 flex flex-col items-center text-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;
const StyledDiv = tw.div`font-display min-h-screen text-secondary-500 p-8 overflow-hidden`;
const FormDiv = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-orange-500 text-gray-100 w-full py-4 rounded-lg hover:bg-orange-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-purple-100 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${props => `background-image: url("${props.imageSrc}");`}
  ${tw`m-12 xl:m-16 w-full max-w-sm bg-contain bg-center bg-no-repeat`}
`;
const illustrationImageSrc = illustration;
const SubmitButtonIcon = LoginIcon;
const Description = tw.p`text-gray-700 font-medium text-sm text-center mx-auto max-w-xs`

export default function DemoCreateAccount (){

  const [Firstname, setFirstname] = React.useState("");
  const [Lastname, setLastname] = React.useState("");
  const [DeveloperEmail, setDeveloperEmail] = React.useState("");
  const [DeveloperName, setDeveloperName] = React.useState("");
  const [redirect, setRedirect] = React.useState(false);
  const [Password, setPassword] = React.useState("");

  function updateInputState (event) {
    if (event.target.name === "firstname") {
      setFirstname(event.target.value);
    }
    if (event.target.name === "lastname") {
      setLastname(event.target.value);
    }
    if (event.target.name === "developeremail") {
      setDeveloperEmail(event.target.value);
    }
    if (event.target.name === "developername") {
      setDeveloperName(event.target.value);
    }
    
  }

  async function CreateDemoDeveloperAPI() {
    const response = await fetch('https://demo.app.snagglist.com/api/createdemodeveloper', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        firstname: Firstname,
        lastname: Lastname,
        developeremail: DeveloperEmail,
        developername: DeveloperName
      })
    });

    const data = await response.json();

    return data;
  };

  async function createDemoAccount(event){
    event.preventDefault();
    const apiresponse = await CreateDemoDeveloperAPI();
    if (apiresponse.status === "200") {
      setPassword(apiresponse.password);  
      setRedirect(true);
    } 
    else if (apiresponse.status === "400") {
      alert("This email address appears to already be in use. Please try again.");
    } else {
      alert("An error occurred. Please try again.");
    }
  }

return (
  <div>
  { redirect ? 
    (<Redirect to={{ pathname: "/demoaccountsuccess", state: { password: Password, email: DeveloperEmail } }} />) :  
  <StyledDiv>
    <Header />
    <Container style={{backgroundColor: "#293241", marginTop: '20px'}}>
      <Content>
        <MainContainer>
          <MainContent>
            <Heading>Get instant access to a Snagglist demo!</Heading>
            <Description>The perfect way to explore how Snagglist could work for your business</Description>
            <FormContainer>
              <form onSubmit={createDemoAccount}>
                <Input name="firstname" type="text" placeholder="First Name" onChange={updateInputState}/>
                <Input name="lastname" type="text" placeholder="Last Name" onChange={updateInputState}/>
                <Input name="developeremail" type="email" placeholder="Email Address" onChange={updateInputState}/>
                <Input name="developername" type="text" placeholder="Developer Company Name" onChange={updateInputState}/>
                <SubmitButton type="submit">
                  <SubmitButtonIcon type="button" className="icon" />
                  <span className="text">Create Demo Account</span>
                </SubmitButton>
              </form>
            </FormContainer>
          </MainContent>
        </MainContainer>
        <IllustrationContainer>
          <IllustrationImage imageSrc={illustrationImageSrc} />
        </IllustrationContainer>
      </Content>
    </Container>
    <Footer />
  </StyledDiv>
  }
  </div>
)};
