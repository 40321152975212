import {React} from "react";
import tw from "twin.macro";
import Hero from "components/hero/Hero.js";
import Features from "components/features/Feature.js";
import FAQ from "components/faqs/Faq.js";
import Footer from "components/footers/Footer.js";
import DownloadApp from "components/cta/DownloadApp";
import customerSupportIllustrationSrc from "images/customer-support-illustration.svg";
import DemoSubscribe from "components/forms/DemoSubscribe.js";
import Pricing from "components/pricing/Pricing.js";

const StyledDiv = tw.div`font-display min-h-screen text-secondary-500 p-8 overflow-hidden`;

function HomePage () {

  return (
    <StyledDiv className='App'>
      <Hero className="p-8" />
      <DemoSubscribe />
      <Features />
      <Pricing />
      <DownloadApp />
      <FAQ
        imageSrc={customerSupportIllustrationSrc}
        imageContain={true}
        imageShadow={false}
        subheading="FAQs"
        heading={
          <>
            Do you have <span tw="text-primary-500">Questions ?</span>
          </>
        }
      />
      <Footer />
      </StyledDiv>
  );
};

export default HomePage;
